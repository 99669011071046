import FeatureCard, {
	FeatureCardLayout,
	FeatureCardProps,
} from "@components/FeatureCard/FeatureCard";
import { PageLayout, TypographyV2 } from "@components/index";
import { StaticImage } from "gatsby-plugin-image";
import { JoinInstitutionsV2 } from "pages/landingpagev2/_components/_JoinInstitutionsV2/_JoinInstitutionsV2";
import React from "react";
import * as styles from "./orders.module.scss";
import { OrdersHero } from "./_components/_OrdersHero/_OrdersHero";
import TitleWithImage from "../virtual-freezers/_components/_TitleWithImage";
import LabConsumablesCards from "../lab-consumables/_components/LabConsumablesCards";
import { CardWithIconProps } from "@components/CardWithIcon/CardWithIcon";
import { Faqs } from "@components/LimsSoftware/Faqs";
import Margins from "@components/Margins/Margins";

const cards: FeatureCardProps[] = [
	{
		title: "Easily manage and submit orders",
		image: (
			<StaticImage
				src={"./_assets/order_detail.png"}
				alt="Easily manage and submit orders"
			/>
		),
		list: [
			"Get a comprehensive view of all order requests and their current status on one dashboard.",
			"Keep things clear with high-level overviews, so you always know where each item stands.",
			"Speed up the order submission process with user-friendly tools, making approvals and processing a breeze.",
		],
		className: styles.cardWrapper,
	},
	{
		title: "Collaborate for in-depth order tracking ",
		image: (
			<StaticImage
				src={"./_assets/orders_img_2.png"}
				alt="Collaborate for in-depth order tracking "
			/>
		),
		list: [
			"Foster team collaboration when it comes to incoming and outgoing orders, improving communication and accuracy.",
			"A detailed order log helps you keep track of progress, including comments, receipts, and other essential attachments.",
			"Maintain transparency and end-to-end documentation for every order so your operations can go off without a hitch.",
		],
		orientation: "right",
		className: styles.cardWrapper,
	},
	{
		title: "Find orders quickly and customize workflows ",
		image: (
			<StaticImage
				src={"./_assets/orders_img_3.png"}
				alt="Find orders quickly and customize workflows "
			/>
		),
		list: [
			"Use keyword-based searches to instantly locate specific orders and optimize response times.",
			"Take advantage of a flexible LIMS system that lets you adjust workflow stages to fit custom order statuses.",
			"Tailor the platform to your unique operational needs so that it aligns perfectly with your internal processes.",
		],
		className: styles.cardWrapper,
	},
];
const labConsumablesCards: CardWithIconProps[] = [
	{
		icon: (
			<svg
				width={32}
				height={33}
				viewBox="0 0 32 33"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<rect
					y="0.666016"
					width={32}
					height={32}
					rx={8}
					fill="#F5F7FF"
				/>
				<g clipPath="url(#clip0_898_7036)">
					<path
						d="M23 7.66602H13C11.8954 7.66602 11 8.56145 11 9.66602V19.666C11 20.7706 11.8954 21.666 13 21.666H23C24.1046 21.666 25 20.7706 25 19.666V9.66602C25 8.56145 24.1046 7.66602 23 7.66602Z"
						stroke="#2C52F7"
						strokeWidth={2}
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						d="M21 21.666V23.666C21 24.1964 20.7893 24.7052 20.4142 25.0802C20.0391 25.4553 19.5304 25.666 19 25.666H9C8.46957 25.666 7.96086 25.4553 7.58579 25.0802C7.21071 24.7052 7 24.1964 7 23.666V13.666C7 13.1356 7.21071 12.6269 7.58579 12.2518C7.96086 11.8767 8.46957 11.666 9 11.666H11"
						stroke="#2C52F7"
						strokeWidth={2}
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</g>
				<defs>
					<clipPath id="clip0_898_7036">
						<rect
							width={24}
							height={24}
							fill="white"
							transform="translate(4 4.66602)"
						/>
					</clipPath>
				</defs>
			</svg>
		),
		title: "A source of truth for your inventory",
		description:
			"From reagents to custom item types - we customize to match exactly how it is in the lab.",
		to: "INVENTORY",
		Cta: "Virtual freezers",
	},
	{
		icon: (
			<svg
				width={32}
				height={33}
				viewBox="0 0 32 33"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<rect
					y="0.666016"
					width={32}
					height={32}
					rx={8}
					fill="#F5F7FF"
				/>
				<g clipPath="url(#clip0_898_5927)">
					<path
						d="M16 14.666C16 13.0747 15.3679 11.5486 14.2426 10.4234C13.1174 9.29816 11.5913 8.66602 10 8.66602H7V10.666C7 12.2573 7.63214 13.7834 8.75736 14.9087C9.88258 16.0339 11.4087 16.666 13 16.666H16"
						stroke="#2C52F7"
						strokeWidth={2}
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						d="M16 18.666C16 17.0747 16.6321 15.5486 17.7574 14.4234C18.8826 13.2982 20.4087 12.666 22 12.666H25V13.666C25 15.2573 24.3679 16.7834 23.2426 17.9087C22.1174 19.0339 20.5913 19.666 19 19.666H16"
						stroke="#2C52F7"
						strokeWidth={2}
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						d="M16 24.666V14.666"
						stroke="#2C52F7"
						strokeWidth={2}
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</g>
				<defs>
					<clipPath id="clip0_898_5927">
						<rect
							width={24}
							height={24}
							fill="white"
							transform="translate(4 4.66602)"
						/>
					</clipPath>
				</defs>
			</svg>
		),
		title: "Finally, an ELN built for scientists",
		description:
			"It’s never been easier to cycle across all your experiments, protocols, and attachments in one place.",
		to: "NOTEBOOK",
		Cta: "Explore ELN",
	},
	{
		icon: (
			<svg
				width={32}
				height={33}
				viewBox="0 0 32 33"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<rect
					y="0.666016"
					width={32}
					height={32}
					rx={8}
					fill="#F5F7FF"
				/>
				<path
					d="M16 6.66602C16.5917 6.66602 17.1713 6.7174 17.7348 6.81595M16 6.66602C10.4772 6.66602 6 11.1432 6 16.666C6 22.1889 10.4772 26.666 16 26.666M16 6.66602V26.666M21.738 8.47502C22.6922 9.14471 23.5241 9.97691 24.1934 10.9314M25.8501 14.9316C25.9486 15.495 26 16.0745 26 16.666C26 17.2575 25.9486 17.8371 25.8501 18.4004M24.1892 22.4066C23.5203 23.359 22.6896 24.1893 21.7369 24.8578M17.7328 26.5164C17.17 26.6148 16.591 26.666 16 26.666"
					stroke="#2C52F7"
					strokeWidth="1.5"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</svg>
		),
		title: "Transparent and scalable pricing",
		description:
			"Finally, simple and predictable pricing that scales with your team.",
		to: "PRICING",
		Cta: "View pricing",
	},
];
const faqs = [
	{
		question: "What are the benefits of using Genemod’s order feature?",
		children: [
			`Genemod’s order feature provides a comprehensive view of all order requests and their current status on a single dashboard. Users can maintain transparency and thorough documentation for every order through a detailed order log that includes comments, receipts, and other essential attachments.`,
		],
		id: "1",
	},
	{
		question:
			"Can I integrate the order feature with other features in Genemod?",
		children: [
			<TypographyV2
				variant="BODY_TEXT_MEDIUM"
				weight="REGULAR"
				tabletVariant="BODY_TEXT_MEDIUM"
				mobileVariant="BODY_TEXT_MEDIUM"
				color="text-helper"
			>
				You can integrate the order feature with several other features
				in Genemod. For example, in Genemod’s{" "}
				<a className={styles.link} href="/products/alerts">
					stock tracker,
				</a>{" "}
				users are alerted when an item falls below a customized
				threshold, allowing you to order items directly from the stock
				tracker. Additionally, when you receive ordered products or
				items, you can add them directly to the Repository and assign
				their locations.
			</TypographyV2>,
		],
		id: "2",
	},
	{
		question: "What are the order statuses in the Genemod’s order system?",
		children: [
			`Genemod’s order statuses include Requested, Approved, Ordered, Received, and Denied. After the order is received, the user can archive the order.`,
		],
		id: "3",
	},
	{
		question: "Can anyone approve the order?",
		children: [
			`Users can enable the option for 'Orders must be approved by admins' in the settings. If this option is turned on, only admins will be able to approve orders.`,
		],
		id: "4",
	},
	{
		question: "Is there a search function in the order feature?",
		children: [
			`Yes, the order feature includes a search box. Additionally, there are filters that allow you to sort by requester, requested date, source/vendor, and last updated date range.`,
		],
		id: "5",
	},
	{
		question: "Can I export orders?",
		children: [`Yes, you can export orders into a CSV file.`],
		id: "7",
	},
	{
		question: "Can I get email notifications for orders?",
		children: [
			`Yes, you can enable or disable email notifications and Genemod in-platform notifications for when an order is placed, approved, and completed.`,
		],
		id: "8",
	},
];
const Orders = () => {
	return (
		<PageLayout seoOptions={{ type: "PREDEFINED", pageName: "orders" }}>
			<Margins className={styles.margins}>
				<OrdersHero />
				<FeatureCardLayout className={styles.layout}>
					{cards.map((card, index) => {
						return (
							<FeatureCard
								font
								sectionAlignment
								{...card}
								key={index}
							/>
						);
					})}
				</FeatureCardLayout>
				<div className={styles.titleWithImageWrapper}>
					<TitleWithImage />
				</div>

				<div className={styles.integrated_bg}>
					<JoinInstitutionsV2
						outerContainerClassName={styles.joinInstitutions}
					/>
				</div>
				<LabConsumablesCards cards={labConsumablesCards} />
			</Margins>

			<Faqs title="Frequently asked questions" faqs={faqs} />
		</PageLayout>
	);
};

export default Orders;
