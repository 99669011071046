import React, { FunctionComponent, ReactNode, useState } from "react";
import * as styles from "./Faq.module.scss";
import TypographyV2 from "@components/TypographyV2/TypographyV2";
import SVG from "react-inlinesvg";
import MINUS from "@assets/icons/Lims/minus.svg";
import CHEVRON_DOWN from "@assets/icons/Lims/chevron-down.svg";
import Expand from "react-expand-animated";

type FaqProps = {
	question: string;
	id?: string;
	defaultOpen?: boolean;
	children?: ReactNode;
};
export const Faq: FunctionComponent<FaqProps> = (props) => {
	const [open, setOpen] = useState<boolean>(props.defaultOpen ?? false);
	return (
		<div className={styles.faqWrapper}>
			<div className={styles.faqHeader} role="button" onClick={() => setOpen((prev) => !prev)}>
				<TypographyV2
					variant="BODY_TEXT_MEDIUM"
					weight="REGULAR"
					tabletVariant="BODY_TEXT_MEDIUM"
					mobileVariant="BODY_TEXT_MEDIUM"
					color="text-secondary"
					className={styles.question}
				>
					{props.question}
				</TypographyV2>
				<div
					className={styles.minus}
				>
					{open ? <SVG src={MINUS} /> : <SVG src={CHEVRON_DOWN} />}
				</div>
			</div>
			<Expand open={open}>
				<div className={styles.faqBody}>
				{props.children }
				</div>
			</Expand>
		</div>
	);
};
