import React, { FunctionComponent, ReactNode } from "react";
import * as styles from "./faqs.module.scss";
import TypographyV2 from "@components/TypographyV2/TypographyV2";
import { Faq } from "./Faq";

type IProps = {
	title: string;
	faqs: Faq[];
};
type Faq = {
	question: string;
	children: ReactNode[] | String[];
	id: string;
};
const getBodyElement = (content: string | ReactNode) => {
	return (
		<TypographyV2
			variant="BODY_TEXT_MEDIUM"
			weight="REGULAR"
			tabletVariant="BODY_TEXT_MEDIUM"
			mobileVariant="BODY_TEXT_MEDIUM"
			color="text-helper"
		>
			{content}
		</TypographyV2>
	);
};
export const Faqs: FunctionComponent<IProps> = (props) => {
	return (
		<div className={styles.faqsWrapper}>
			<div className={styles.container}>
				<TypographyV2
					variant="HEADING_2"
					weight="MEDIUM"
					tabletVariant="HEADING_2"
					mobileVariant="HEADING_2"
					color="brand-500"
					center
					className={styles.heading}
				>
					{props.title}
				</TypographyV2>
				{(props?.faqs ?? [])?.map((faq: Faq) => {
					return (
						<div key={faq.id}>
							<Faq question={faq.question}>
								{faq.children?.map((body_content, index) => {
									return (
										<div key={index}>
											{getBodyElement(body_content)}
										</div>
									);
								})}
							</Faq>
						</div>
					);
				})}
			</div>
		</div>
	);
};
